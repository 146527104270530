.center {
  margin: 0 auto;
  text-align: center;
}
.anim-box {
  background-color: black;
  border-radius: 0px;
  display: grid;
}
.anim-box .scanner {
  animation-play-state: running;
}

/* animated laser beam */
.scanner {
  width: 100%;
  height: 3px;
  background-color: #8aa385;
  opacity: 1;
  position: absolute;
  box-shadow: 0px 0px 8px 10px #8aa38566;
  animation-name: scan;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: paused;
  z-index: 2;
}
@media only screen and (min-width: 600px) {
  .anim-box {
    background-color: black;
    border-radius: 10px 10px 0 0;
  }
}

@keyframes scan {
  0% {
    box-shadow: 0px 0px 8px 10px rgba(138, 163, 133, 0.4);
    top: 50%;
  }
  25% {
    box-shadow: 0px 6px 8px 10px rgba(138, 163, 133, 0.4);
    top: 5px;
  }
  75% {
    box-shadow: 0px -6px 8px 10px rgba(138, 163, 133, 0.4);
    top: 98%;
  }
}
