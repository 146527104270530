/* Width */
.width-500px {
  width: calc(500px - 225px);
}
.width-400px {
  width: calc(400px - 125px);
}
.width-450px {
  width: 450px;
}
.max-width-500px {
  max-width: 500px;
}
.width-100vw {
  max-width: 100vw;
}
.width-90vw {
  max-width: 90vw;
}

/* Height */
.heigth-40px {
  max-height: 40px;
}

@media only screen and (min-width: 600px) {
  /* Height */
  .heigth-100vh {
    max-height: 100vh;
  }
  .heigth-500px {
    max-height: 500px;
  }

  /* Width */
  .width-550px {
    width: 550px;
  }
  .width-500px {
    width: 500px;
  }
  .width-400px {
    width: 400px;
  }
  .width-416px {
    width: 416px;
  }
}
