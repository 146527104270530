.card {
  display: grid;
  justify-items: center;
  row-gap: 16px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px #8aa3852b;
  border-color: #b9d0b5;
  border-width: 1px;
  z-index: 1;
}
.card:focus {
  outline: 0;
  outline-color: transparent;
  outline-style: none;
}
.card.active {
  background-color: #eff7ed;
}
.bottom-card {
  display: grid;
  height: 17vh;
  border-color: #8aa385;
  border-width: 1px;
  box-shadow: 0px -6px 10px #8aa3852b;
  border-radius: 20px 20px 0 0;
  background-image: url(../../assets/flip-background-2.svg);
  background-size: 207px;
  background-repeat: no-repeat;
  background-position: right;
  background-color: #e6eae1;
  grid-template-columns: 5fr 1fr;
  align-items: center;
  align-self: flex-end;
}

.card.rekomendasi-product-card {
  background-color: transparent;
  border: none;
  padding: 16px;
}

.analisa-kulit-introduce {
  padding: 32px;
}
.keranjang-belanja-card {
  background-color: white;
  border-radius: 10px;
  grid-template-rows: max-content 1fr;
  padding: 32px 16px;
}

.info-kondisi-kulit-card{
  grid-template-rows: repeat(2, max-content) 1fr;
  height: 100%;
  width: 100%;
}
@media only screen and (min-width: 600px) {
  .card {
    display: grid;
    justify-items: center;
    row-gap: 16px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px #8aa3852b;
    border-color: #b9d0b5;
    border-width: 1px;
    z-index: 1;
  }

  .bottom-card {
    grid-template-columns: 1fr 4fr 1fr;
  }

  .card.rekomendasi-product-card {
    background-color: white;
    border-color: #b9d0b5;
    border-width: 1px;
    padding: 32px;
  }

  .info-kondisi-kulit-card{
    grid-template-rows: repeat(2, max-content) 1fr;
    height: 100%;
    width: 75%;
  }

  .analisa-kulit-introduce {
    padding: 64px;
  }
  .card.keranjang-belanja-card {
    padding: 32px;
  }
}
