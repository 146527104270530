.image-analisa-kulit {
  background-color: #e6eae1;
  box-shadow: 0px 3px 20px #00000064;
  width: 100vw;
  max-height: 100vh;
  align-self: center;
  align-self: flex-start;
}
.image-capture {
  height: 85vh;
  border-radius: 0px;
  min-width: 100vw;
}
.product-image {
  max-width: 30vw;
  max-height: 10vh;
}
@media only screen and (min-width: 600px) {
  .hasil-analisa-image {
    display: none;
  }
  .product-image {
    max-width: 150px;
    max-height: 125px;
  }

  .image-analisa-kulit {
    border-radius: 10px 10px 0 0;
    background-color: #e6eae1;
    box-shadow: 0px 3px 20px #00000064;
    max-height: 70vh;
    width: max-content;
    max-width: max-content;
    align-self: flex-end;
  }

  @-moz-document url-prefix() {
    .image-analisa-kulit {
      border-radius: 10px 10px 0 0;
      background-color: #e6eae1;
      box-shadow: 0px 3px 20px #00000064;
      height: 70vh;
      align-self: flex-end;
    }

    .image-capture {
      min-height: 100%;
      border-radius: 10px 10px 0 0;
      min-width: 0vw;
      max-width: 65vw;
      object-fit: cover;
    }
  }

  .image-capture {
    min-height: 100%;
    border-radius: 10px 10px 0 0;
    min-width: 0vw;
    max-width: 65vw;
    object-fit: cover;
  }
}
