/* Position */
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
/* Display */
.d-flex {
  display: flex;
}
.d-grid {
  display: grid;
}
.d-inline-grid {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  column-gap: 8px;
}
.d-none {
  display: none;
}
/* Align Items*/
.align-items-center {
  align-items: center;
}
.align-items-flex-start {
  align-items: flex-start;
}
.align-items-flex-end {
  align-items: flex-end;
}

/* Align Content */
.align-content-center {
  align-content: center;
}
.align-content-flex-start {
  align-content: flex-start;
}
.align-content-flex-end {
  align-content: flex-end;
}

/* Align Self */
.align-self-center {
  align-self: center;
}
.align-self-flex-start {
  align-self: flex-start;
}
.align-self-flex-end {
  align-self: flex-end;
}

/* Justify Content*/
.justify-content-center {
  justify-content: center;
}
.justify-content-flex-start {
  justify-content: flex-start;
}
.justify-content-flex-end {
  justify-content: flex-end;
}

/* Justify Items */
.justify-items-center {
  justify-items: center;
}
.justify-items-flex-start {
  justify-items: flex-start;
}
.justify-items-flex-end {
  justify-items: flex-end;
}

/* Justify Self */
.justify-self-center {
  justify-self: center;
}
.justify-self-flex-start {
  justify-self: flex-start;
}
.justify-self-flex-end {
  justify-self: flex-end;
}

/* Grid Column */ 
.gc-1-4 {
  display: grid;
  justify-content: center;
}

/* Row Gap */
.rg-8 {
  row-gap: 8px;
}