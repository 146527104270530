/* Padding */
.p-0 {
  padding: 0;
}
.p-4px {
  padding: 4px;
}
.p-8 {
  padding: 8px;
}
.p-16 {
  padding: 16px;
}
.p-32 {
  padding: 32px;
}
.p-64 {
  padding: calc(64px - 32px);
}
/* Padding Top */
.pt-16 {
  padding-top: 16px;
}
.pt-32 {
  padding-top: calc(32px - 16px);
}
/* Padding Y */
.py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

/* Padding X */
.px-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.px-187 {
  padding-left: 187px;
  padding-right: 187px;
}

@media only screen and (min-width: 600px) {
  /* Padding */
  .p-64 {
    padding: 64px;
  }

  /* Padding Top */
  .pt-32 {
    padding-top: 32px;
  }

  /* Padding Y */
  .py-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  /* Padding X */
  .px-64 {
    padding-left: 64px;
    padding-right: 64px;
  }
}
