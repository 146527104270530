.full-page-wrapper {
  position: relative;
  display: grid;
  background-image: url(../../assets//background-2.svg);
  background-size: 100%;
  background-position-x: left;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-color: #e6eae1;
  grid-template-rows: 1fr max-content;
  min-height: 100vh;
}

.left-wrapper {
  display: grid;
  align-items: center;
  justify-items: center;
  background-image: url(../../assets//background-1.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  grid-template-rows: max-content max-content 1fr;
}

.right-wrapper {
  display: grid;
  align-items: center;
  justify-items: center;
  row-gap: 16px;
}

/* Home Page */
.carousel-card-wrapper {
  display: grid;
  column-gap: 8px;
  grid-auto-flow: column;
}

/* Kondisi Kulit Page */
.kondisi-kulit-right-wrapper {
  grid-template-rows: max-content 1fr;
}
.button-kondisi-kulit-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 16px;
  column-gap: 16px;
}

.form-group-kondisi-kulit {
  border-bottom-color: #e3ede1;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.page-card-kondisi-kulit-wrapper {
  display: flex;
  justify-content: center;
}

.bottom-card-kondisi-kulit-wrapper {
  align-self: flex-end;
  display: grid;
  justify-content: center;
  grid-auto-flow: row;
  row-gap: 16px;
}

/* Analisa Kulit Page */
.analisa-kulit-wrapper {
  position: absolute;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: rgba(98, 116, 75, 0.78);
  height: 100vh;
  width: 100vw;
  z-index: 1;
  overflow: hidden;
  padding: 16px;
}

.capture-wrapper {
  grid-template-columns: repeat(2, 1fr) 2fr 1fr;
}

.bottom-card-wrapper {
  position: relative;
  display: grid;
  height: 24vh;
}

/* Hasil Analisa Page */
.full-page-wrapper.hasil-analisa-wrapper {
  background-image: none;
  background-color: transparent;
}
.hasil-analisa-wrapper {
  grid-template-columns: 1fr;
}

.hasil-analisa-left-wrapper {
  display: none;
}

.top-hasil-analisa-right-wrapper {
  background-image: url(../../assets//background-2.svg);
  background-size: 100%;
  background-position-x: left;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-color: #e6eae1;
  padding: 32px;
}

.hasil-analisa-card-wrapper {
  grid-auto-flow: row;
  row-gap: 0;
}

.list-hasil-analisa-wrapper {
  width: 100%;
  height: max-content;
  display: grid;
  align-items: center;
  padding: 0;
  background-color: #e1e8df;
  border-radius: 0;
}

.list-hasil-analisa-card-wrapper {
  display: grid;
  padding: 32px;
  background-color: #e1e8df;
  width: 100%;
  border-radius: 0;
  row-gap: 8px;
  column-gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: max-content;
}

.description-hasil-analisa-card-wrapper {
  display: grid;
  padding: 32px;
  text-align: left;
  grid-template-rows: repeat(2, max-content) 1fr;
  row-gap: 16px;
}

/* Rekomendasi Product Page */
.rekomendasi-produk-full-page-wrapper {
  overflow-y: auto;
}
.rekomendasi-produk-header-wrapper {
  display: grid;
  grid-auto-flow: row;
  width: 100%;
}

.product-wrapper {
  display: grid;
  row-gap: 8px;
  justify-content: center;
  padding: 16px;
  border-radius: 10px;
  border-color: #e3e3e3;
  border-width: 1px;
  border-style: solid;
  width: 40vw;
  background-color: white;
}

.product-button-wrapper {
  grid-template-columns: 1fr repeat(2, max-content);
  display: grid;
  grid-auto-flow: column;
  column-gap: 2px;
}

.rekomendasi-all-product-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  row-gap: 16px;
}

/* Keranjang Belanja */
.keranjang-belanja-wrapper {
  padding: 32px;
  height: 100vh;
  overflow-y: hidden;
}
.keranjang-belanja-content-wrapper {
  width: 100%;
}
@media only screen and (min-width: 600px) {
  .full-page-wrapper {
    height: 100vh;
    background-size: 50%;
  }
  .full-page-wrapper.hasil-analisa-wrapper {
    position: relative;
    display: grid;
    background-image: url(../../assets//background-2.svg);
    background-size: 50%;
    background-position-x: left;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-color: #e6eae1;
    height: 100vh;
    grid-template-rows: 1fr max-content;
    padding: 32px 64px 32px 64px;
  }

  .left-wrapper {
    display: grid;
    align-items: center;
    justify-items: center;
    background-image: url(../../assets//background-1.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    grid-template-rows: max-content max-content 1fr;
    height: 100vh;
    overflow: auto;
  }

  .right-wrapper {
    row-gap: 0px;
  }

  .kondisi-kulit-right-wrapper {
    grid-template-rows: max-content 1fr;
    overflow-y: auto;
  }

  .button-kondisi-kulit-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 16px;
    column-gap: 16px;
  }

  .page-card-kondisi-kulit-wrapper {
    display: flex;
    justify-self: flex-start;
  }

  .bottom-card-kondisi-kulit-wrapper {
    align-self: flex-end;
    grid-auto-flow: column;
    justify-content: stretch;
  }

  .capture-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }

  .hasil-analisa-wrapper {
    grid-template-columns: max-content 1fr;
    column-gap: 16px;
  }

  .hasil-analisa-left-wrapper {
    display: grid;
    grid-auto-rows: max-content;
  }

  .top-hasil-analisa-right-wrapper {
    background-image: none;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    padding: 0;
  }

  .hasil-analisa-card-wrapper {
    grid-template-columns: 1fr 2fr;
    min-height: 70%;
    max-height: 80%;
    row-gap: 0;
  }

  .list-hasil-analisa-wrapper {
    width: 100%;
    height: 70vh;
    display: grid;
    align-items: center;
    padding: 32px 0;
    background-color: #e1e8df;
    border-radius: 10px 0 0 10px;
  }

  .list-hasil-analisa-card-wrapper {
    display: grid;
    padding: 0 32px;
    background-color: #e1e8df;
    width: 100%;
    border-radius: 10px 0 0 10px;
    row-gap: 8px;
    grid-auto-rows: max-content;
    grid-template-columns: none;
    height: 100%;
    overflow-y: auto;
  }

  .list-hasil-analisa-card-wrapper::-webkit-scrollbar{
    width: 6px;
    background-color: #e1e8df;
  }
  .list-hasil-analisa-card-wrapper::-webkit-scrollbar-track{
    background-color: #e1e8df;
  }
  .list-hasil-analisa-card-wrapper::-webkit-scrollbar-thumb{
    background-color: #8aa385;
  }

  .description-hasil-analisa-card-wrapper {
    display: grid;
    padding: 32px;
    text-align: left;
    grid-template-rows: repeat(2, max-content) 1fr;
    row-gap: 0;
  }

  .rekomendasi-produk-header-wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: max-content 1fr;
    width: 100%;
  }

  .rekomendasi-produk-full-page-wrapper {
    overflow-y: hidden;
    height: 100vh;
  }

  .rekomendasi-all-product-wrapper {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    column-gap: 16px;
    overflow-y: auto;
    max-height: 53vh;
  }
  .rekomendasi-all-product-wrapper::-webkit-scrollbar{
    width: 6px;
    background-color: #e1e8df;
  }
  .rekomendasi-all-product-wrapper::-webkit-scrollbar-track{
    background-color: #e1e8df;
  }
  .rekomendasi-all-product-wrapper::-webkit-scrollbar-thumb{
    background-color: #8aa385;
  }

  .product-wrapper {
    display: grid;
    row-gap: 8px;
    justify-content: center;
    padding: 16px;
    border-radius: 10px;
    border-color: #e3e3e3;
    border-width: 1px;
    border-style: solid;
    width: 225px;
    background-color: white;
  }

  .product-button-wrapper {
    grid-template-columns: 1fr repeat(2, max-content);
    display: grid;
    grid-auto-flow: column;
    column-gap: 8px;
  }

  .keranjang-belanja-wrapper {
    padding: 32px 172px;
    overflow-y: hidden;
  }
  .keranjang-belanja-content-wrapper {
    width: 70%;
  }

  .bottom-card-wrapper {
    height: max-content;
  }

  @-moz-document url-prefix() {
    .image-capture-wrapper {
      height: 70vh;
    }
  }
}
