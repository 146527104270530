.layout-1 {
  display: grid;
  grid-auto-flow: row;
  height:auto;
  overflow: auto;
}
@media only screen and (min-width: 600px) {
  .layout-1 {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
    height: 100vh;
  }
}
