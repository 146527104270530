.progress {
  height: 4px;
}
.progress .progress-bar {
  animation-name: animateBar;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 4s;
  background-color: #8aa385;
}
@keyframes animateBar {
  0% {transform: translateX(-100%);}
  100% {transform: translateX(0);}
}