.kandungan-lainnya {
  grid-column: 1 / 3;
}
input[type="file"] {
  display: none;
}
label {
  margin: 0;
}
.d-grid .product-table {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  width: 100%;
  column-gap: 8px;
  row-gap: 8px;
  text-align: right;
  overflow-y: auto;
  max-height: 70vh;
}
.table-footer {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column: 1/3;
  background-color: #dbe6d8;
  border-radius: 5px;
  padding: 4px;
  text-align: right;
  justify-items: flex-end;
  padding: 4px 16px 4px 16px;
  position: sticky;
  bottom: 0;
}
.product-price {
  display: none;
}
.product-price-merge {
  display: block;
}
.price-x-quantity {
  justify-content: right;
  grid-template-columns: 1fr max-content;
}
.opacity-0 {
  opacity: 0%;
  cursor: default;
}

.bottom-card-title {
  display: none;
}

.bottom-card-title-mobile {
  display: block;
  position: absolute;
  margin-left: 32px;
}

.image-name {
  display: none;
}

.image-name-mobile {
  display: block;
  position: absolute;
  margin-right: 32px;
  justify-self: flex-end;
  align-self: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  max-width: 150px;
  padding: 4px 16px;
  margin-top: 8px;
}

.button-text-analisa-kulit {
  display: none;
}

.webcam {
  border-radius: 0;
  min-width: 100vw;
  min-height: 85vh;
  object-fit: contain;
}

.paper-plane-icon {
  display: block;
}

.bottom-card-container {
  position: absolute;
  align-self: flex-end;
  width: 100vw;
}

.analisa-kulit-loading-bar {
  width: max-content;
  grid-column: 3/4;
  justify-self: center;
}

.out-of-stock-label {
  background-color: black;
  width: 30vw;
}
@media only screen and (min-width: 600px) {
  .kandungan-lainnya {
    grid-column: 2 / 4;
  }

  .bottom-card-title {
    display: grid;
  }

  .bottom-card-title-mobile {
    display: none;
  }

  .button-text-analisa-kulit {
    display: flex;
    align-items: center;
  }

  .webcam {
    border-radius: 10px 10px 0 0;
    margin-top: 0;
    min-width: 60vw;
    object-fit: contain;
  }
  .d-grid .product-table {
    display: grid;
    grid-template-columns: max-content 2fr 1fr;
    text-align: center;
    align-items: center;
    width: 100%;
    column-gap: 32px;
    text-align: left;
    justify-content: center;
    overflow-y: auto;
    max-height: 55vh;
  }

  .d-grid .product-table::-webkit-scrollbar {
    width: 6px;
    background-color: #e1e8df;
  }
  .d-grid .product-table::-webkit-scrollbar-track {
    background-color: #e1e8df;
  }
  .d-grid .product-table::-webkit-scrollbar-thumb {
    background-color: #8aa385;
  }

  .table-footer {
    grid-column: 1/4;
    grid-template-columns: 4fr 1fr;
    text-align: center;
  }

  .product-price-merge {
    display: none;
  }
  .product-price {
    display: block;
  }
  .price-x-quantity {
    justify-content: left;
    grid-template-columns: max-content 1fr;
  }

  .paper-plane-icon {
    display: none;
  }

  .image-name {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 16px;
  }

  .image-name-mobile {
    display: none;
  }

  .bottom-card-container {
    position: relative;
    align-self: flex-end;
    max-width: 90vw;
  }

  .analisa-kulit-loading-bar {
    width: max-content;
    grid-column: 2/3;
  }

  .out-of-stock-label {
    background-color: black;
    width: 193px;
  }

  @-moz-document url-prefix() {
    .webcam {
      border-radius: 10px 10px 0 0;
      margin-top: 0;
      max-width: max-content;
      min-height: 70vh;
      object-fit: contain;
    }
  }
}
