/* Font Family */
.belleza {
  font-family: "Belleza", sans-serif;
}
.nunito {
  font-family: "Nunito", sans-serif;
}
.quicksand {
  font-family: "Quicksand", sans-serif;
}

/* Font Weight */
.light {
  font-weight: 300;
}
.regular {
  font-weight: 400;
}
.italic {
  font-weight: 400;
  font-style: italic;
}
.semi-bold {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}
.black {
  font-weight: 900;
}
/* Font Size */
.size-80 {
  font-size: calc(80px / 2);
}
.size-60 {
  font-size: calc(60px / 2);
}
.size-40 {
  font-size: calc(40px - 10px);
}
.size-33 {
  font-size: 33px;
}
.size-32 {
  font-size: calc(32px / 2);
}
.size-24 {
  font-size: calc(24px - 4px);
}
.size-20 {
  font-size: calc(20px - 5px);
}
.size-18 {
  font-size: calc(18px - 4px);
}
.size-17 {
  font-size: calc(17px - 3px);
}
.size-16 {
  font-size: calc(16px - 2px);
}
.size-15 {
  font-size: 15px;
}
.size-14 {
  font-size: 14px;
}
.size-12 {
  font-size: 12px;
}
.size-10 {
  font-size: 10px;
}
.size-8 {
  font-size: 8px;
}

/* Color */
.text-green {
  color: #8aa385;
}
.text-dark-green {
  color: #334E10;
}
.text-red {
  color: #c60000;
}
.text-green:hover {
  color: #8aa385;
}
.text-disabled {
  color: #e3e3e3;
}
.text-disabled:hover {
  color: #e3e3e3;
}
.text-grey {
  color: #c4c4c4;
}
/* Text Align */
.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}

/* Line Height */
.line-height-40 {
  line-height: calc(40px - 15px);
}
.line-height-32 {
  line-height: 32px;
}
.line-height-85percent {
  line-height: 85%;
}


.button-sub-text {
  display: flex;
}
@media only screen and (min-width: 600px) {
  /* Size */
  .size-80 {
    font-size: 80px;
  }
  .size-60 {
    font-size: 60px;
  }
  .size-40 {
    font-size: 40px;
  }
  .size-32 {
    font-size: 32px;
  }
  .size-24 {
    font-size: 24px;
  }
  .size-20 {
    font-size: 20px;
  }
  .size-18 {
    font-size: 18px;
  }
  .size-16 {
    font-size: 16px;
  }
  .size-17 {
    font-size: 17px;
  }
  /* Line Height */
  .line-height-40 {
    line-height: 40px;
  }

  .button-sub-text {
    display: none;
  }
}
