.carousel-card {
  width: 100%;
}
.carousel-indicators-cards {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  bottom: 0;
  left: 0%;
  width: auto;
}
.carousel-indicators-cards li {
  background-color: #656b6b;
}
.carousel-indicators-cards li.active {
  background-color: #656b6b;
}
.carousel-indicators li {
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.carousel-indicators li.active {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.control-prev,
.control-next {
  display: none;
}

.custom-carousel-control-next,
.custom-carousel-control-prev {
  height: max-content;
  align-self: center;
  display: grid;
  background-color: #e1f3df;
  padding: 16px 4px 16px 4px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}

.custom-carousel-control-next > *,
.custom-carousel-control-prev > * {
  color: #8aa385;
}

.carousel-controller {
  display: grid;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 600px) {
  .control-prev,
  .control-next {
    position: relative;
    color: #282d31;
    display: flex;
    align-items: center;
  }

  .carousel-card {
    width: 400px;
    display: grid;
    justify-content: center;
  }

  .custom-carousel-control-next,
  .custom-carousel-control-prev {
    display: none;
  }

  .carousel-controller {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: max-content 1fr max-content;
    justify-content: center;
  }
}
