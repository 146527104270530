.form-group {
  margin: 0;
}
.form-control {
  border-radius: 5px;
  border-width: 1px;
  border-color: #e3e3e3;
  background-color: #f9f9f9;
}
.form-control:focus {
  border-width: 1px;
  border-color: #e3e3e3;
  background-color: #f9f9f9;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #8aa385;
  color: #8aa385;
  box-shadow: none;
  border: none;
}
.input-group-addon {
  background-color: #f9f9f9;
  border-color: #e3e3e3;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-left-width: 0px;
  padding-left: 4px;
  padding-right: 4px;
  align-items: center;
  justify-content: center;
  display: grid;
}

input[type="date"] {
  position: relative;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
input[type="date"]:after {
  color: #555;
  padding: 0 5px;
  border: none;
}

/* change color of symbol on hover */
input[type="date"]:hover:after {
  color: #bf1400;
  border: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
  border: none;
}

/* adjust increase/decrease button */
input[type="date"]::-webkit-inner-spin-button {
  z-index: 1;
}

/* adjust clear button */
input[type="date"]::-webkit-clear-button {
  z-index: 1;
}

input[type="date"]::before { 
  content: attr(data-placeholder);
  width: 100%;
}

/* hide our custom/fake placeholder text when in focus to show the default
 * 'mm/dd/yyyy' value and when valid to show the users' date of birth value.
 */
input[type="date"]:focus::before,
input[type="date"]:valid::before { display: none }