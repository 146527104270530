* {
  margin: 0;
  padding: 0;
}
*:focus {
  box-shadow: none !important;
  text-decoration: none;
}
body {
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  font-size: 13px;
}
* a:hover {
  text-decoration: none;
  color: #282d31;
}
@media only screen and (min-width: 600px) {
  body {
    font-family: "Quicksand", sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
}
