.btn {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-pill {
  border-radius: 25px;
}
.button-circle {
  width: 40px;
  height: 40px;
}
.button-grey {
  background-color: #c4c4c4;
  color: #ffffff;
}
.button-grey:hover {
  color: #ffffff;
}
.button-green {
  background-color: #8aa385;
  color: #ffffff;
}
.button-green:hover {
  color: #ffffff;
}
.button-outline-green {
  border-color: #8aa385;
}
.button-outline-green.active {
  background-color: #eff7ed;
}
.button-white {
  background-color: #ffffff;
  border-color: #8aa385;
  border-style: solid;
  border-width: 1px;
}
.button-red {
  background-color: #d80000;
  color: #ffffff;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  display: grid;
  position: absolute;
}
.button-black {
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
  justify-content: center;
  justify-self: center;
  align-self: flex-end;
  align-items: center;
  position: absolute;
}
.back-button {
  display: none;
}

.button-analisa-kulit {
  height: 40px;
  width: 40px;
  padding: 0px;
}

.button-analisa-kulit.unggah {
  justify-self: center;
}

.btn.capture {
  grid-column: 3/4;
}

.button-capture {
  width: 52px;
}

.button-submit {
  border-radius: 25px;
}

.button-navigasi-hasil-analisa {
  padding-left: 16px;
  padding-right: 16px;
}
.button-selanjutnya {
  justify-self: center;
}
.button-lainnya {
  grid-column: auto;
}
@media only screen and (min-width: 600px) {
  .back-button {
    display: flex;
  }

  .button-analisa-kulit {
    display: grid;
    column-gap: 4px;
    grid-auto-flow: column;
    align-content: center;
    height: 14px;
    width: max-content;
    padding: 16px 32px 16px 32px;
  }

  .button-analisa-kulit.unggah {
    justify-self: flex-end;
  }

  .button-capture {
    width: 76px;
  }

  .btn.capture {
    grid-column: 2/3;
  }

  .button-submit {
    border-radius: 5px;
  }

  .button-navigasi-hasil-analisa {
    padding-left: 32px;
    padding-right: 32px;
  }
  .button-selanjutnya {
    justify-self: flex-end;
  }
  .button-lainnya {
    grid-column: 1/2;
  }
}
